




















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Modal from '../Modal.vue';
import {VideoCategoriesInterface} from '@/types/VideoCategoriesInterface';

@Component({
  components: {Modal},
})
export default class SearchVideoFilter extends Vue {
  @Prop(Array) readonly filters!: VideoCategoriesInterface[];
  @Prop(Object) readonly currentFilter!: VideoCategoriesInterface;

  changedFilter: VideoCategoriesInterface | null = this.currentFilter;
  showFilters = false;

  @Watch('currentFilter')
  onFilterChange() {
    this.changedFilter = this.currentFilter;
  }

  changeFilters() {
    this.$emit('filtersChanged', this.changedFilter);
    this.showFilters = false;
  }

  resetFilter() {
    this.changedFilter = null;
  }

  cancel() {
    this.changedFilter = this.currentFilter;
    this.showFilters = false;
  }
}
