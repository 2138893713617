




















import {Component, Mixins} from 'vue-property-decorator';
import SearchVideoFilter from '@/components/modals/SearchVideoFilter.vue';
import SearchMixin from '@/mixins/SearchMixin';
import VideoPreviewMixin from '@/mixins/VideoPreviewMixin';

@Component({
  components: {SearchVideoFilter},
})
export default class SearchVideos extends Mixins(SearchMixin, VideoPreviewMixin) {}
