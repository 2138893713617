import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Pagination} from '@/types/Pagination';
import {ProductInterface} from '@/types/productInterface';
import {SearchParamsInterface} from '@/types/SearchParamsInterface';
import {ModelDataInterface} from '@/types/userDataInterface';
import {VideoInterface} from '@/types/videoInterface';
import {vxm} from '@/store';
import moment from 'moment';
import {VideoCategoriesInterface} from '@/types/VideoCategoriesInterface';

@Component
export default class SearchMixin extends Vue {
  @Prop(String) query!: string;
  loadingData = false;
  lastSearch = '';
  modelsResult: Pagination<ModelDataInterface[]> = {count: 0, items: []};
  videosResult: Pagination<VideoInterface[]> = {count: 0, items: []};
  modelsResultCount = 0;
  videosResultCount = 0;
  hideVideoLoadingBtn = false;
  hideModelLoadingBtn = false;

  searchResult: Pagination<ProductInterface[] | ModelDataInterface[] | VideoInterface[]> = {count: 0, items: []};
  params: SearchParamsInterface = {
    page: 1,
    take: 20,
    q: this.query,
    type: this.type,
  };
  isRequestSend = false;
  filters: VideoCategoriesInterface[] = [];
  currentFilter: VideoCategoriesInterface | null = null;

  mounted() {
    this.getFirstPage();
    this.scroll();
    if (this.$route.name === 'search-videos' || this.$route.name === 'search-videos-category') {
      vxm.fan
        .getCategories()
        .then((res) => {
          this.filters = res.data;

          if (this.$route.query.categoryId) {
            for (const filter of this.filters) {
              if (filter._id === this.$route.query.categoryId) {
                this.currentFilter = filter;
                break;
              }
            }
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }

  @Watch('query')
  onQueryChange() {
    this.getFirstPage();
  }

  @Watch('currentFilter')
  onFilterChange(val: VideoCategoriesInterface, oldVal: VideoCategoriesInterface) {
    if (val !== oldVal) {
      this.getFirstPage();
    }
  }

  get type(): string {
    if (this.$route.name === 'search-videos' || this.$route.name === 'search-videos-category') {
      return 'video';
    }

    return this.$route.name === 'search-products' ? 'product' : 'performer';
  }

  get isPerformer(): boolean {
    return vxm.user.role === 'performer';
  }

  scroll() {
    window.onscroll = () => {
      const bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >= document.documentElement.scrollHeight;

      if (bottomOfWindow && !this.isRequestSend && this.searchResult.count > this.params.page * this.params.take) {
        this.getNextPage();
      }
    };
  }

  getFirstPage() {
    this.loadingData = false;
    this.modelsResult = {count: 0, items: []};
    this.videosResult = {count: 0, items: []};
    this.search();
  }

  getNextPage() {
    this.params.page++;
    this.search();
  }

  loadMoreVideos() {
    if (this.videosResultCount < 0) {
      return false;
    } else {
      this.search();
    }
  }
  loadMoreModels() {
    if (this.modelsResultCount < 0) {
      return false;
    } else {
      this.search();
    }
  }

  search() {
    this.loadingData = true;
    if (!this.query) {
      this.loadingData = false;
      this.modelsResult = {count: 0, items: []};
      this.videosResult = {count: 0, items: []};
      return;
    }

    const params = {
      page: 1,
      take: 20,
      q: this.query,
    };

    this.lastSearch = this.query;

    this.$gtag.event('search', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: this.query,
    });

    vxm.fan
      .search({...params, type: 'performer'})
      .then((res) => {
        this.modelsResultCount = res.data.count;
        this.modelsResult = res.data;
        this.loadingData = false;
        if (this.modelsResultCount > params.take) {
          this.hideModelLoadingBtn = true;
        }
      })
      .catch((error) => {
        return error;
        this.hideModelLoadingBtn = false;
      });

    if (!this.isPerformer) {
      vxm.fan
        .search({...params, type: 'video'})
        .then((res) => {
          this.videosResultCount = res.data.count;
          this.videosResult = res.data;
          this.loadingData = false;
          if (this.videosResultCount > params.take) {
            this.hideVideoLoadingBtn = true;
          }
        })
        .catch((error) => {
          return error;
          this.hideVideoLoadingBtn = false;
        });
    }
  }

  formatDate(date: string) {
    return moment(date).format('MM-DD-YYYY');
  }
}
